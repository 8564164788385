.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  height: 3rem;
  width: auto;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-link {
  text-decoration: none;
  margin-left: 2rem;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.navbar-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.navbar-link:hover::after {
  transform: translateX(0);
}

.contact-btn {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  margin-left: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

.contact-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
}

.contact-btn:hover::after {
  width: 300px;
  height: 300px;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
}

.navbar-toggle span {
  width: 25px;
  height: 3px;
  margin: 2px 0;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .navbar-links {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    padding: 1rem 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    opacity: 0;
    visibility: hidden;
  }

  .navbar-links.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .navbar-link,
  .contact-btn {
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar-toggle.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .navbar-toggle.active span:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggle.active span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}

/* Scrolled state */
.navbar.scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.scrolled .navbar-link,
.navbar.scrolled .navbar-toggle span {
  color: #ff6600;
}

.navbar.scrolled .contact-btn {
  background-color: #ff6600;
  color: #ffffff;
}

/* Non-scrolled state */
.navbar:not(.scrolled) .navbar-link,
.navbar:not(.scrolled) .navbar-toggle span {
  color: #ffffff;
}

.navbar:not(.scrolled) .contact-btn {
  background-color: #ffffff;
  color: #ff6600;
}
