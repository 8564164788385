/* .itinerary-section {
  position: relative;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://plus.unsplash.com/premium_photo-1664537975122-9c598d85816e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  opacity: 0.1;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #ff6600 #f3f4f6;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f3f4f6;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #ff6600;
  border-radius: 20px;
  border: 2px solid #f3f4f6;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.itinerary-section h1 {
  position: relative;
  display: inline-block;
  text-shadow: 0 0 10px rgba(255, 102, 0, 0.5);
  animation: glow 2s ease-in-out infinite alternate;
}

.itinerary-section h1::after {
  content: "";
  position: absolute;
  top: 0;
  right: -8px;
  width: 2px;
  height: 100%;
  background-color: #ff6600;
  animation: blink 0.7s infinite;
}

@media (min-width: 768px) {
  .itinerary-section h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    animation: typewriter 4s steps(50, end) 1s 1 normal both,
      blink 0.7s infinite;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px rgba(255, 102, 0, 0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 102, 0, 0.8);
  }
}

.text-ananda-blue {
  transition: color 0.3s ease, transform 0.3s ease;
}

.text-ananda-blue:hover {
  color: #ff6600;
  transform: translateX(5px);
}

.text-gray-700 li {
  position: relative;
  padding-bottom: 2px;
}

.text-gray-700 li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #0066cc;
  transition: width 0.3s ease;
}

.text-gray-700 li:hover::after {
  width: 100%;
}

.image-carousel {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  touch-action: pan-y;
}

.image-carousel img {
  transition: transform 0.3s ease-in-out;
}

.image-carousel:hover img {
  transform: scale(1.05);
}

.image-carousel::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-carousel.swiping-left::after {
  left: 10px;
  transform: translateY(-50%);
  opacity: 1;
}

.image-carousel.swiping-right::after {
  right: 10px;
  transform: translateY(-50%);
  opacity: 1;
}

.image-carousel.swiping-left::after,
.image-carousel.swiping-right::after {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}

.image-carousel.swiping-left::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 19l-7-7 7-7'%3E%3C/path%3E%3C/svg%3E");
}

.image-carousel.swiping-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 5l7 7-7 7'%3E%3C/path%3E%3C/svg%3E");
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.thumbnail-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.thumbnail.active {
  border: 2px solid #ff6600;
} */
.itinerary-section {
  position: relative;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://plus.unsplash.com/premium_photo-1664537975122-9c598d85816e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  opacity: 0.1;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

/* Scrollbar Styles */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #ff6600 #f3f4f6;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f3f4f6;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #ff6600;
  border-radius: 20px;
  border: 2px solid #f3f4f6;
}

/* Typewriter effect for the main title */
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.itinerary-section h1 {
  position: relative;
  display: inline-block;
  text-shadow: 0 0 10px rgba(255, 102, 0, 0.5);
  animation: glow 2s ease-in-out infinite alternate;
}

.itinerary-section h1::after {
  content: "";
  position: absolute;
  top: 0;
  right: -8px;
  width: 2px;
  height: 100%;
  background-color: #ff6600;
  animation: blink 0.7s infinite;
}

@media (min-width: 768px) {
  .itinerary-section h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    animation: typewriter 4s steps(50, end) 1s 1 normal both,
      blink 0.7s infinite;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px rgba(255, 102, 0, 0.5);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 102, 0, 0.8);
  }
}

/* Smooth hover effect for day titles */
.text-ananda-blue {
  transition: color 0.3s ease, transform 0.3s ease;
}

.text-ananda-blue:hover {
  color: #ff6600;
  transform: translateX(5px);
}

/* Fancy underline animation for activities */
.text-gray-700 li {
  position: relative;
  padding-bottom: 2px;
}

.text-gray-700 li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #0066cc;
  transition: width 0.3s ease;
}

.text-gray-700 li:hover::after {
  width: 100%;
}

/* Image carousel styles */
.image-carousel {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  touch-action: pan-y;
}

.image-carousel img {
  transition: transform 0.3s ease-in-out;
}

.image-carousel:hover img {
  transform: scale(1.05);
}

.image-carousel::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-carousel.swiping-left::after {
  left: 10px;
  transform: translateY(-50%);
  opacity: 1;
}

.image-carousel.swiping-right::after {
  right: 10px;
  transform: translateY(-50%);
  opacity: 1;
}

.image-carousel.swiping-left::after,
.image-carousel.swiping-right::after {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}

.image-carousel.swiping-left::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 19l-7-7 7-7'%3E%3C/path%3E%3C/svg%3E");
}

.image-carousel.swiping-right::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 5l7 7-7 7'%3E%3C/path%3E%3C/svg%3E");
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

/* Thumbnail styles */
.thumbnail-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.thumbnail.active {
  border: 2px solid #ff6600;
}

/* ItineraryAccordion animations */
.accordion-item {
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.accordion-button {
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: #f3f4f6;
}

.accordion-content {
  will-change: height;
}

.accordion-list {
  padding-left: 20px;
}

.accordion-list-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.accordion-list-item.visible {
  opacity: 1;
  transform: translateY(0);
}
