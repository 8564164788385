@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.expertise-card {
  animation: float 3s ease-in-out infinite;
}

.expertise-card:hover {
  animation-play-state: paused;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}
