.customize-itinerary {
  background-color: #f7f7f7;
  background-image: linear-gradient(135deg, #f7f7f7 0%, #e0e0e0 100%);
}

.customize-itinerary h1 {
  background: linear-gradient(45deg, #ff6600, #ff8c00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

.form-group {
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.form-group label {
  transition: color 0.3s ease;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

.form-group:hover label {
  color: #ff6600;
}

.form-group input,
.form-group select,
.form-group textarea {
  transition: all 0.3s ease;
  border: 2px solid #e2e8f0;
  background-color: #fff;
}

.form-group:hover input,
.form-group:hover select,
.form-group:hover textarea {
  border-color: #ff6600;
  box-shadow: 0 0 0 2px rgba(255, 102, 0, 0.2);
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

/* Responsive styles */
@media (max-width: 768px) {
  .customize-itinerary h1 {
    font-size: 2.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }
}

/* Accessibility enhancements */
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: 2px solid #ff6600;
  outline-offset: 2px;
  box-shadow: 0 0 10px rgba(255, 102, 0, 0.3);
}

/* Custom scrollbar for textarea */
.form-group textarea::-webkit-scrollbar {
  width: 8px;
}

.form-group textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.form-group textarea::-webkit-scrollbar-thumb {
  background: #ff6600;
  border-radius: 4px;
}

.form-group textarea::-webkit-scrollbar-thumb:hover {
  background: #ff8c00;
}

/* Additional styles for form elements */
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ff6600'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  padding-right: 40px;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

/* Button styles */
button[type="submit"] {
  background-color: #ff6600;
  color: white;
  font-weight: bold;
  padding: 14px 28px;
  border-radius: 9999px;
  font-size: 18px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(255, 102, 0, 0.2);
}

button[type="submit"]:hover {
  background-color: #ff8c00;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 140, 0, 0.3);
}

button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

/* Image showcase styles */
.image-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 48px;
}

.image-card {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.image-card:hover {
  transform: translateY(-5px) scale(1.03);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.image-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-card:hover img {
  transform: scale(1.1);
}

.image-card-content {
  padding: 20px;
}

.image-card h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ff6600;
}

.image-card p {
  font-size: 14px;
  color: #4a5568;
  line-height: 1.6;
}
