/* @media (max-width: 480px) {
  .hero-section {
    height: auto;
  }

  .side-content-panel,
  .bottom-panel {
    position: relative;
    width: 100%;
    height: auto;
  }

  .smaller-slider {
    display: none;
  }

  .country-info {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 1rem;
  }

  .country-item {
    display: inline-block;
    margin-right: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .hero-section {
    height: auto;
  }

  .side-content-panel,
  .bottom-panel {
    position: relative;
    width: 100%;
  }

  .smaller-slider {
    width: 100%;
    height: 200px;
  }

  .country-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (min-width: 769px) {
  .side-content-panel,
  .bottom-panel {
    width: 33.333%;
  }
} */

h1 {
  font-family: sans-serif;
}
p {
  font-family: serif;
}
