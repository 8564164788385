.packages-section {
  position: relative;
  padding: 5rem 0;
  overflow: hidden;
  background-color: #f3f4f6;
}

.packages-section h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #ff6600;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  display: inline-block;
}

.packages-section h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #ff6600;
  animation: typewriter 2s steps(20) forwards, blink 0.7s infinite;
}

.package-card {
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.package-card h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 0.5rem;
}

.package-card p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: white;
  margin-bottom: 1rem;
}

.package-card .btn {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  transition: all 0.3s ease;
}

.customize-btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  border-radius: 9999px;
  background-color: #ff6600;
  color: white;
  transition: all 0.3s ease;
}

.customize-btn:hover {
  background-color: white;
  color: #ff6600;
  transform: scale(1.05);
}

@keyframes typewriter {
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .packages-section h2 {
    font-size: 2rem;
  }

  .package-card h3 {
    font-size: 1.25rem;
  }

  .package-card p {
    font-size: 0.875rem;
  }

  .customize-btn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }
}
