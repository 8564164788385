@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;700&display=swap");

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-hidden {
  opacity: 0;
  transform: translateY(20px);
}

.animate-on-scroll {
  animation: fadeInUp 0.5s ease-out forwards;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.bg-gradient-radial {
  background-image: radial-gradient(circle, var(--tw-gradient-stops));
}

.animate-hidden {
  opacity: 0;
}

.clip-path-blob {
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
}

.bg-world-map {
  background-image: url("../../public/images/world-map.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.1;
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.font-heading {
  font-family: "Montserrat", sans-serif;
}

.font-body {
  font-family: "Roboto", sans-serif;
}
